import { Tag, TagTooltip } from "@components/library";
import { FONTS } from "@constants";
import { RfpPageRequest } from "@tsTypes/index";
import { MAX_KEYWORD_DISPLAY_COUNT } from "src/constants/requests";
import styled from "styled-components";

interface Props {
  request: RfpPageRequest;
}

const RfpKeywordsContainer = ({ request }: Props) => {
  const disciplines = request.disciplines;
  const areasOfExpertise = request.areas_of_expertise;

  return (
    <Container>
      {disciplines.length > 0 && (
        <div>
          <Heading>Disciplines</Heading>
          <Tags>
            {disciplines.slice(0, MAX_KEYWORD_DISPLAY_COUNT).map((discipline) => (
              <Tag key={discipline} content={discipline} size="sm" theme="neutral" />
            ))}
            {disciplines.length > MAX_KEYWORD_DISPLAY_COUNT && (
              <TagTooltip
                tags={disciplines.slice(MAX_KEYWORD_DISPLAY_COUNT).map((discipline) => (
                  <Tag key={discipline} content={discipline} size="sm" theme="neutral" />
                ))}
              >
                <Tag
                  content={`${disciplines.length - MAX_KEYWORD_DISPLAY_COUNT} more`}
                  size="sm"
                  theme="neutral"
                />
              </TagTooltip>
            )}
          </Tags>
        </div>
      )}
      {areasOfExpertise.length > 0 && (
        <div>
          <Heading>Areas of Expertise</Heading>
          <Tags>
            {areasOfExpertise.slice(0, MAX_KEYWORD_DISPLAY_COUNT).map((area) => (
              <Tag key={area} content={area} size="sm" theme="neutral" />
            ))}
            {areasOfExpertise.length > MAX_KEYWORD_DISPLAY_COUNT && (
              <TagTooltip
                tags={areasOfExpertise.slice(MAX_KEYWORD_DISPLAY_COUNT).map((area) => (
                  <Tag key={area} content={area} size="sm" theme="neutral" />
                ))}
              >
                <Tag
                  content={`${areasOfExpertise.length - MAX_KEYWORD_DISPLAY_COUNT} more`}
                  size="sm"
                  theme="neutral"
                />
              </TagTooltip>
            )}
          </Tags>
        </div>
      )}
    </Container>
  );
};

export default RfpKeywordsContainer;

const Container = styled.div`
  margin-bottom: 48px;

  & > div {
    margin-top: 15px;
  }

  &:first-child {
    margin-top: 0;
  }
`;

const Heading = styled.div`
  ${FONTS.MEDIUM_1};
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  line-height: 1;
  & > div {
    margin-top: 8px;
  }
`;
