import { Button, SelectableDropdown } from "@components/library";
import { DropdownOption } from "@components/library/Dropdowns/DropdownOption";
import RfpQuestionSubmittedModal from "@components/modals/RfpQuestionSubmittedModal";
import { COLORS, FONTS } from "@constants";
import { toggleLoginModal } from "@redux/actions/profileActions";
import { RootState } from "@redux/store";
import { getRequestComments } from "@requests/requestComments";
import { AnyTypeUser } from "@requests/users";
import { RequestCommentPartial, RfpPageRequest } from "@tsTypes/index";
import { RequestForExpertsShowResponse } from "@tsTypes/request_for_experts/show";
import { RequestForStartupsShowResponse } from "@tsTypes/request_for_startups/show";
import { LaunchableRequestStatus, RequestableType } from "@tsTypes/requests";
import { UserRole } from "@tsTypes/users";
import {
  canAccessRequestAsLead,
  getLaunchableRequestStatus,
  LaunchableRequest,
} from "@utils/requestUtils";
import { checkRfpCompanyHidden } from "@utils/rfpUtils";
import { Dispatch, ForwardedRef, forwardRef, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_ROLES } from "src/constants/users";
import styled from "styled-components";
import RequestComment from "./RequestComment";
import RequestDiscussionSubmitForm from "./RequestDiscussionSubmitForm";

interface Props {
  request: RfpPageRequest | RequestForStartupsShowResponse | RequestForExpertsShowResponse;
  isCommenting: boolean;
  setIsCommenting: Dispatch<SetStateAction<boolean>>;
  setIsShareModalOpen: Dispatch<SetStateAction<boolean>>;
}

const SORT_BY_OPTIONS: DropdownOption[] = [
  {
    value: "votes",
    label: "Most upvoted",
  },
  {
    value: "recent",
    label: "Most recent",
  },
  {
    value: "awaiting_response",
    label: "Awaiting response",
  },
];

const RequestDiscussion = (
  { request, isCommenting, setIsCommenting, setIsShareModalOpen }: Props,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const dispatch = useDispatch();

  const isForRfs = request.requestable_type === RequestableType.REQUEST_FOR_STARTUP;
  const isCompanyHidden = checkRfpCompanyHidden(request, currentUser);
  const companyName = isCompanyHidden ? "the company" : (request.company as any)?.company_name;

  const _canAccessRequestAsLead = canAccessRequestAsLead(currentUser as AnyTypeUser, request);
  const showRfpDiscussion =
    getLaunchableRequestStatus(request as LaunchableRequest) === LaunchableRequestStatus.ACTIVE;

  const [sortBy, setSortBy] = useState<DropdownOption>(
    _canAccessRequestAsLead ? SORT_BY_OPTIONS[2] : SORT_BY_OPTIONS[0]
  );
  const [comments, setComments] = useState<RequestCommentPartial[]>([]);

  const canAskQuestion = [UserRole.SCIENTIST, UserRole.UNIVERSITY_ADMIN].includes(currentUser.role);

  const refreshComments = async () => {
    const { request_comments } = await getRequestComments(request.id, sortBy.value);
    setComments(request_comments);
  };

  useEffect(() => {
    refreshComments();
  }, [sortBy]);

  useEffect(() => {
    if (_canAccessRequestAsLead) setSortBy(SORT_BY_OPTIONS[2]);
  }, [currentUser.role]);

  const onCommentSuccess = () => {
    refreshComments();
    setIsCommenting(false);

    if (canAskQuestion) {
      setSuccessModalOpen(true);
    }
  };

  return (
    <>
      <RfpQuestionSubmittedModal
        onClose={() => setSuccessModalOpen(false)}
        isOpen={successModalOpen}
        handleOpenShareModal={() => setIsShareModalOpen(true)}
      />
      <Heading ref={ref} id="q-a">
        Q&A with {companyName}
      </Heading>
      {showRfpDiscussion ? (
        <>
          <p>
            Ask the team at {companyName} any questions you have about this{" "}
            {isForRfs ? "startup program" : "RFP"}.
          </p>
          {(() => {
            if (currentUser.id === undefined) {
              return (
                <QuestionCTA>
                  <div>Ask your question</div>
                  <Button
                    onClick={() => {
                      dispatch(toggleLoginModal({ open: true }));
                    }}
                  >
                    Sign In
                  </Button>
                </QuestionCTA>
              );
            }
            if (isCommenting) {
              return (
                <RequestDiscussionSubmitForm
                  companyName={companyName ?? "the company"}
                  request={request as any}
                  onSuccess={onCommentSuccess}
                  setIsCommenting={setIsCommenting}
                />
              );
            }
            if (canAskQuestion) {
              return (
                <Button
                  margin="32px 0 16px 0"
                  onClick={() => {
                    setIsCommenting(true);
                  }}
                >
                  Ask your question
                </Button>
              );
            }
          })()}
        </>
      ) : (
        <Closed>
          <p>The Q&amp;A is now closed.</p>
        </Closed>
      )}
      {comments.length > 0 && (
        <SortBy>
          Sort by:
          <SelectableDropdown
            options={
              currentUser.role === USER_ROLES.sponsor
                ? SORT_BY_OPTIONS
                : SORT_BY_OPTIONS.filter((option) => option.value !== "replies")
            }
            value={sortBy}
            onChange={(e) => setSortBy(e)}
          />
        </SortBy>
      )}
      <Questions>
        {comments.map((comment) => (
          <RequestComment
            key={comment.id}
            request={request as RfpPageRequest}
            comment={comment}
            commentTreeLevel={0}
            canAnswer={_canAccessRequestAsLead}
            isCompanyHidden={isCompanyHidden}
            refreshComments={refreshComments}
          />
        ))}
      </Questions>
    </>
  );
};

export default forwardRef(RequestDiscussion);

const QuestionCTA = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  margin: 32px 0 16px 0;
  background: ${COLORS.BLUE_LIGHT_250};
  padding: 16px 32px;
  border-radius: 12px;
`;
const SortBy = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 16px;
  ${FONTS.REGULAR_2};
`;
const Heading = styled.div`
  ${FONTS.HEADING_2_SEMIBOLD};
  margin-bottom: 24px;
  &:not(:first-of-type) {
    margin-top: 48px;
  }
`;
const Questions = styled.div`
  margin-top: 16px;
`;
const Closed = styled.div`
  margin-bottom: 16px;
`;
