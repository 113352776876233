import toast from "@components/Toast";
import UserVerificationCode from "@components/UserVerificationCode";
import { TextButton } from "@components/library";
import { COLORS, FEATURE_FLAGS, FONTS } from "@constants";
import { useSearchParams } from "@hooks/router";
import haloLogo from "@images/logos/halo_logo.svg";
import { fetchSubscription } from "@redux/actions/companyActions";
import { setCurrentUser } from "@redux/actions/profileActions";
import { RootState } from "@redux/store";
import { getCompany } from "@requests/companies";
import { getGoogleScholarAuthors } from "@requests/googleScholarAuthors";
import { trackRegistration } from "@requests/hubspot";
import { UserNotVerifiedError, cancelSignUp, onboard } from "@requests/users";
import { proposal_review_system_path } from "@routes/routes";
import { useMutation, useQuery } from "@tanstack/react-query";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { ShowCompany } from "@tsTypes/index";
import { UserRole } from "@tsTypes/users";
import { identify, track } from "@utils/appUtils";
import appsignal from "@utils/appsignal";
import { t } from "@utils/i18n";
import { toastErrorHandler } from "@utils/requests";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import WhiteLabelNavbar from "../WhiteLabel/Navbar";
import { Progress } from "./Progress";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { DISPLAY_INCREMENT, Step3aGoogleScholar } from "./Step3aGoogleScholar/Step3aGoogleScholar";
import Step4 from "./Step4";

export enum UserDetailedType {
  // Scientist user types
  UNIVERSITY_SCIENTIST = "university scientist",
  STARTUP_SCIENTIST = "startup scientist",
  SUPPLIER = "supplier",
  CONSULTANT = "consultant",
  // Sponsor user types
  COMPANY_SPONSOR = "company sponsor",
  VENTURE_CAPITAL_SPONSOR = "venture capital sponsor", // Deprecated
  GOVERNMENT_SPONSOR = "government sponsor", // Deprecated
  // Admin user types
  UNIVERSITY_ADMINISTRATOR = "university administrator",
}

export interface UserInfo {
  work: string;
  role: string;
  detailedType?: UserDetailedType;
}

function OnboardingContainer() {
  const isWhiteLabel = location.pathname.startsWith("/partner");

  const [profileInfo, setProfileInfo] = useState<any>({});
  const [completionState, setCompletionState] = useState({
    step1: false,
    step2: false,
    step3a: false,
    step3b: false,
  });
  const [userInfo, setUserInfo] = useState<UserInfo>({
    work: "",
    role: "",
    detailedType: undefined,
  });
  const [company, setCompany] = useState<ShowCompany | null>(null);

  const [selectedGoogleScholarAuthorId, setSelectedGoogleScholarAuthorId] = useState<number | null>(
    null
  );
  const [googleScholarAuthorsDisplayCount, setGoogleScholarAuthorsDisplayCount] =
    useState(DISPLAY_INCREMENT);
  const [filteredGoogleScholarAuthorIds, setFilteredGoogleScholarAuthorIds] = useState<number[]>(
    []
  );

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  const { company_identifier } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchParams] = useSearchParams();
  const commenterInviteRequest = searchParams.get("commenter_invite_request");

  useEffect(() => {
    if (currentUser.profile_info?.id) {
      history.push("/");
    }
    if (currentUser.verified) {
      onContinue("step1");
    }
  }, []);

  useEffect(() => {
    if (completionState.step1 && commenterInviteRequest) {
      setUserInfo({
        ...userInfo,
        role: "company-sponsor",
        detailedType: UserDetailedType.COMPANY_SPONSOR,
      });
      setCompletionState({ ...completionState, step1: true, step2: true });
    }
  }, [completionState.step1]);

  useEffect(() => {
    (async () => {
      if (company_identifier) {
        try {
          const response = await getCompany(company_identifier);
          setCompany(response.company);
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, [company_identifier]);

  const scrollToRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    scrollToRef.current?.scrollIntoView({ behavior: "smooth" });
    track(SegmentEventName.View, {
      react_component: "OnboardingContainer",
      completionState,
    });
  }, [completionState]);

  const {
    data: googleScholarAuthors,
    refetch: refetchGoogleScholarAuthors,
    isFetching: isGoogleScholarAuthorsFetching,
  } = useQuery({
    queryKey: ["getGoogleScholarAuthors"],
    queryFn: getGoogleScholarAuthors,
    refetchInterval: () => {
      if (!FEATURE_FLAGS.GOOGLE_SCHOLAR_ONBOARDING_STEP || completionState.step2) {
        return false;
      }
      return 3000;
    },
    onError: (error) => {
      // Even if this fails, the user can still onboard, so quiet the error
      appsignal.sendError(error as any, (span) => {
        span.setAction("OnboardingContainer#getGoogleScholarAuthors");
      });
    },
  });

  const onboardMutation = useMutation({
    mutationFn: (userType: string) =>
      onboard({
        userType,
        profileInfo,
        googleScholarAuthorUserId: selectedGoogleScholarAuthorId,
        detailedType: userInfo.detailedType,
        commenterInviteRequest,
      }),
    onError: (error, variables, context): void => {
      if (error instanceof UserNotVerifiedError) {
        setCompletionState({
          step1: false,
          step2: false,
          step3a: false,
          step3b: false,
        });
        toast.error(t("pages.onboarding_container.user_not_verified_message"));
        return;
      }
      toastErrorHandler(error, variables, context);
    },
    onSuccess: async (updatedUser: any) => {
      dispatch(setCurrentUser(updatedUser));

      await trackRegistration({
        ...updatedUser,
        ...(updatedUser.userType === "admin" && { role: 2 }),
      });
      identify(updatedUser);

      if (updatedUser.role === UserRole.SPONSOR) {
        dispatch(fetchSubscription(updatedUser.profile_info.company_identifier));

        if (commenterInviteRequest) {
          history.push(proposal_review_system_path({ request: commenterInviteRequest }));
        }
      }

      onContinue("step3b");
    },
  });

  const cancelSignUpMutation = useMutation({
    mutationFn: () => cancelSignUp(completionState.toString()),
    onSuccess: () => {
      // Take user back to landing page after canceling sign up
      window.location.href = "/";
    },
    onError: toastErrorHandler,
  });

  const handleOnboard = (userType: string) => {
    if (onboardMutation.isLoading) {
      return;
    }
    onboardMutation.mutate(userType);
  };

  const onVerify = () => {
    dispatch(
      setCurrentUser({
        ...currentUser,
        verified: true,
      })
    );
    onContinue("step1");
  };

  const updateValue = (value, newValue) => {
    if (newValue !== userInfo[value]) {
      setProfileInfo({
        ...(profileInfo?.university ? { university: profileInfo.university } : null),
      });
    }
    // if user selects a new work value, then clear role value
    if (value === "work" && newValue !== userInfo.work) {
      setUserInfo({
        work: newValue,
        role: "",
      });
    } else {
      setUserInfo((prevUserInfo) => {
        const updatedUserInfo = { ...prevUserInfo };
        updatedUserInfo[value] = newValue;
        return updatedUserInfo;
      });
    }
  };

  // when the back button is pressed, set the previous step to incomplete
  const onBack = (previousStep) => {
    setCompletionState({ ...completionState, [previousStep]: false });
  };

  // when the continue button is pressed, set the current step to complete
  const onContinue = (currentStep) => {
    setCompletionState({ ...completionState, [currentStep]: true });
  };

  const currentStep = () => {
    const { step1, step2, step3a, step3b } = completionState;
    if (!step1) {
      return <UserVerificationCode onContinue={onVerify} />;
    } else if (!step2) {
      return (
        <Step2
          values={userInfo}
          setWork={(v) => updateValue("work", v)}
          setRole={(v) => updateValue("role", v)}
          setDetailedType={(v) => updateValue("detailedType", v)}
          onContinue={() => {
            refetchGoogleScholarAuthors();
            onContinue("step2");
          }}
        />
      );
    } else if (!step3a && FEATURE_FLAGS.GOOGLE_SCHOLAR_ONBOARDING_STEP) {
      return (
        <Step3aGoogleScholar
          userInfo={userInfo}
          onContinue={onContinue}
          onBack={onBack}
          authors={googleScholarAuthors ?? []}
          selectedAuthorId={selectedGoogleScholarAuthorId}
          setSelectedAuthorId={setSelectedGoogleScholarAuthorId}
          numDisplayed={googleScholarAuthorsDisplayCount}
          setNumDisplayed={setGoogleScholarAuthorsDisplayCount}
          filteredAuthorIds={filteredGoogleScholarAuthorIds}
          setFilteredAuthorIds={setFilteredGoogleScholarAuthorIds}
          isFetching={isGoogleScholarAuthorsFetching}
        />
      );
    } else if (!step3b) {
      return (
        <Step3
          userType={userInfo.role}
          handleOnboard={handleOnboard}
          isSubmitting={onboardMutation.isLoading}
          onBack={() => {
            setCompletionState({
              ...completionState,
              // Go all the way back to step2 if step3a was not supposed to be shown
              step2:
                Number(googleScholarAuthors?.length) > 0 &&
                (userInfo.role === "research-scientist" || userInfo.role === "company-scientist"),
              step3a: false,
            });
          }}
          profileInfo={profileInfo}
          setProfileInfo={setProfileInfo}
          user={currentUser}
          selectedGoogleScholarAuthor={googleScholarAuthors?.find(
            ({ id }) => id === selectedGoogleScholarAuthorId
          )}
        />
      );
    }
    return <Step4 userType={userInfo.role} />;
  };

  const allStepsComplete = Object.values(completionState).every((step) => step);

  return (
    <OnboardingPage>
      {isWhiteLabel ? (
        <>
          <WhiteLabelNavbar company={company} branding={null} />
          <WhiteLabelBanner>Create an account</WhiteLabelBanner>
        </>
      ) : (
        <Banner center={!FEATURE_FLAGS.CANCEL_SIGN_UP}>
          <img src={haloLogo} />
          {FEATURE_FLAGS.CANCEL_SIGN_UP && !allStepsComplete && (
            <TextButton
              text={t("pages.onboarding_container.cancel_sign_up")}
              color={COLORS.HALO_BLUE}
              iconName={null}
              onClick={() => {
                track(SegmentEventName.Click, {
                  react_component: "OnboardingContainer",
                  ui_component: "Cancel sign up button",
                });
                cancelSignUpMutation.mutate({});
              }}
              disabled={cancelSignUpMutation.isLoading}
            />
          )}
        </Banner>
      )}
      <Progress completionState={completionState} />
      <StepContainer>
        <div ref={scrollToRef} />
        {currentStep()}
      </StepContainer>
    </OnboardingPage>
  );
}

export default OnboardingContainer;

const OnboardingPage = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
`;

const Banner = styled.div`
  display: flex;
  justify-content: ${({ center }) => (center ? "center" : "space-between")};
  align-items: center;
  position: relative;
  text-align: center;
  background-color: ${COLORS.WHITE};
  height: 84px;
  margin-bottom: 48px;
  width: 100vw;
  padding: 26px 36px;
  border-bottom: 1px solid ${COLORS.NEUTRAL_200};
`;

const WhiteLabelBanner = styled.div`
  ${FONTS.HEADING_1_SEMIBOLD};
  margin-top: 40px;
  margin-bottom: 30px;
`;

const StepContainer = styled.div`
  padding-bottom: 40px;
`;
