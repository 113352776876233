import { useEffect, useState } from "react";
import ReactCodeInput from "react-verification-code-input";
import styled from "styled-components";

import toast from "@components/Toast";
import { Button, TextInput } from "@components/library";
import { COLORS, FEATURE_FLAGS, FONTS } from "@constants";
import { RootState } from "@redux/store";
import { useMutation } from "@tanstack/react-query";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { track } from "@utils/appUtils";
import { t, t_link } from "@utils/i18n";
import { toastErrorHandler } from "@utils/requests";
import { useSelector } from "react-redux";
import { TextInputError } from "src/components/library/Inputs/TextInput";
import { cancelSignUp, resendEmailInvite, signOut, verifyUser } from "src/requests/users";
import { StepContainer, StepHeader } from "src/views/Onboarding/StepStyles";
import ResendConfirmationModal from "./ResendConfirmationModal";

function UserVerificationCode({ onContinue }) {
  const currentUserEmail = useSelector((state: RootState) => state.profiles.currentUser.email);
  const isTablet = useSelector((state: RootState) => state.window.isTablet);

  const [isEmailInputVisible, setIsEmailInputVisible] = useState(false);
  const [email, setEmail] = useState(currentUserEmail || "");

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [emailInputError, setEmailInputError] = useState<TextInputError | null>(null);

  const verificationMutation = useMutation({
    mutationFn: verifyUser,
    onSuccess: (result) => {
      if (result) {
        onContinue();
      } else {
        setError(true);
      }
    },
    onError: toastErrorHandler,
  });

  const resendVerificationMutation = useMutation({
    mutationFn: resendEmailInvite,
    onSuccess: () => {
      toast.success("A new verification code has been sent to your email.");
      setIsEmailInputVisible(false);
    },
    onError: (err: any) => {
      switch (err.response?.status) {
        case 409:
          toast.error(
            t_link(
              "pages.onboarding_container.user_verification_code.error.email_taken",
              <span
                style={{ textDecoration: "underline" }}
                onClick={async () => {
                  await cancelSignUp("resendEmailVerification409");
                  window.location.href = "/login";
                }}
              >
                log in instead
              </span>
            )
          );
          return false;
        case 429:
          toast.error(
            t("pages.onboarding_container.user_verification_code.error.too_many_requests")
          );
          return false;
      }
      throw error;
    },
  });

  useEffect(() => {
    // Auto-verify when user enters 4 digits
    if (code.length === 4 && !error) {
      verificationMutation.mutate(code);
    }
  }, [code]);

  const handleOnChange = (verificationCode: string) => {
    if (verificationCode.length && error) setError(false);
    setCode(verificationCode);
  };

  return (
    <>
      <ResendConfirmationModal
        confirmationModalOpen={confirmationModalOpen}
        setConfirmationModalOpen={setConfirmationModalOpen}
      />
      <StepContainer step={1} data-testid="onboarding-step1" width="470px">
        <StepHeader subheading="Welcome to Halo" heading="Please verify your email address." />
        <StyledCodeInput
          error={error}
          required
          type="number"
          fields={4}
          fieldHeight={isTablet ? 100 : 121}
          fieldWidth={isTablet ? 85 : 109}
          placeholder={[" ", " ", " ", " "]}
          onChange={handleOnChange}
          disabled={verificationMutation.isLoading}
        />
        <DropdownRow>
          {error && (
            <DropdownTitle style={{ color: COLORS.RED }}>
              {t("pages.onboarding_container.user_verification_code.error.incorrect")}
            </DropdownTitle>
          )}
          <DropdownTitle>
            {t("pages.onboarding_container.user_verification_code.instructions")}{" "}
            {FEATURE_FLAGS.UPDATE_EMAIL_IN_ONBOARDING ? (
              <ResendEmailLink onClick={() => setIsEmailInputVisible(true)}>
                {t("pages.onboarding_container.user_verification_code.resend_email")}
              </ResendEmailLink>
            ) : (
              <ResendEmailLink onClick={() => resendVerificationMutation.mutate(email)}>
                {t("pages.onboarding_container.user_verification_code.resend_email")}
              </ResendEmailLink>
            )}
          </DropdownTitle>
          {isEmailInputVisible && (
            <EmailSection
              onSubmit={(e) => {
                e.preventDefault();
                track(SegmentEventName.Submit, {
                  react_component: "UserVerificationCode",
                  action: "Resend email verification",
                  currentUserEmail,
                  sentToEmail: email,
                });

                if (email.trim() === "") {
                  setEmailInputError({
                    hasError: true,
                    errorMessage: "Please enter a valid email address.",
                  });
                  return;
                }
                setEmailInputError(null);
                resendVerificationMutation.mutate(email);
              }}
            >
              <TextInput
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                errors={emailInputError ? [emailInputError] : undefined}
              />
              <Button
                type="submit"
                variant="primary"
                size="sm"
                margin="12px 0 0"
                disabled={resendVerificationMutation.isLoading}
              >
                {t("pages.onboarding_container.user_verification_code.resend_code")}
              </Button>
            </EmailSection>
          )}
          <Padding height={20} />
          {FEATURE_FLAGS.SIGN_OUT_ON_ONBOARDING && (
            <Button
              type="button"
              variant="destructive-secondary"
              onClick={async () => {
                await signOut();
              }}
              margin="0 16px 0 0"
            >
              Sign Out
            </Button>
          )}
        </DropdownRow>
      </StepContainer>
    </>
  );
}

export default UserVerificationCode;

const DropdownRow = styled.div`
  width: 324px;
  position: relative;
  align-self: flex-start;
  margin-bottom: 28px;

  &:last-of-type {
    margin-bottom: 35px;
  }
`;

const DropdownTitle = styled.div`
  ${FONTS.MEDIUM_2};
  width: 100%;
  padding-bottom: 7px;
  color: ${COLORS.BLACK};
`;

// Styles below overwrite library's default styles to match our design system
const StyledCodeInput = styled(ReactCodeInput)`
  margin-bottom: 33px;
  width: unset !important;
  > div {
    > input {
      background: ${COLORS.NEUTRAL_100};
      border: 0.5px solid ${COLORS.HALO_BLUE} !important;
      border-radius: 4px;
      color: ${COLORS.BLACK};
      font-size: 32px;
      caret-color: ${COLORS.BLACK} !important;
    }
    > input:focus {
      border: 2px solid ${COLORS.HALO_BLUE} !important;
    }
    > input:not(:last-child) {
      margin-right: 8px;
    }
    > input:focus + input {
      border-left: 0.5px solid ${COLORS.HALO_BLUE};
    }
    > input:focus + input:not(:placeholder-shown) {
      border-left: 0.5px solid ${COLORS.HALO_BLUE};
    }
    > input:last-child:focus,
    input:not(:placeholder-shown) {
      border-color: ${COLORS.HALO_BLUE};
    }
    > input {
      border-color: ${(props) => props.error && COLORS.RED} !important;
    }
  }
`;

const ResendEmailLink = styled.span`
  ${FONTS.REGULAR_2};
  text-align: left;
  white-space: no-wrap;
  color: ${COLORS.HALO_BLUE};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const Padding = styled.div`
  height: ${(props) => (props.height ? `${props.height}px` : "10px")};
  display: block;
`;

const EmailSection = styled.form`
  margin: 12px 0;
`;
